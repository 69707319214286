import React from 'react';
import { Container, Section } from '../global';

const OurCurriculumTeacher = () => (
    <Section id="our-curriculum-teacher" >
        <Container>
            <h2>The Teacher’s Role </h2>
            <p>In the classroom, their mission is to build a positive relationship with each child. Our teachers will develop age-appropriate activities to ensure a lifetime of learning.</p>
            <p>Education is not only a focal point for children, but for our teachers as well. They have the opportunity to further their growth by obtaining a childcare development credential, AA, or bachelor’s degree in Early Childhood Education. All teachers are required to fulfill state licensing standards with an additional 21 hours of continuing education annually.</p>
        </Container>
    </Section>
);

export default OurCurriculumTeacher;
