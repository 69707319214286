import Layout from '../components/common/Layout';
import Navbar from '../components/common/Navbar';
import Footer from '../components/sections/Footer';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import OurCurriculum from '../components/sections/OurCurriculum';
import OurCurriculumInfants from '../components/sections/OurCurriculumInfants';
import OurCurriculumMobile from '../components/sections/OurCurriculumMobile';
import OurCurriculumTwos from '../components/sections/OurCurriculumTwos';
import OurCurriculumPreSchool from '../components/sections/OurCurriculumPreSchool';
import OurCurriculumSchoolAge from '../components/sections/OurCurriculumSchoolAge';
import OurCurriculumTeacher from '../components/sections/OurCurriculumTeacher';


const OurCurriculumPage = () => (
  <Layout>
    <Navbar/>
    <div class="main-content">
        <OurCurriculum/>
        <Tabs>
          <TabList>
            <Tab>Infants</Tab>
            <Tab>Infant Mobile</Tab>
            <Tab>Twos</Tab>
            <Tab>PreSchool</Tab>
            <Tab>School Age</Tab>
          </TabList>
          <TabPanel><OurCurriculumInfants/></TabPanel>
          <TabPanel><OurCurriculumMobile/></TabPanel>
          <TabPanel><OurCurriculumTwos/></TabPanel>
          <TabPanel><OurCurriculumPreSchool/></TabPanel>
          <TabPanel><OurCurriculumSchoolAge/></TabPanel>
        </Tabs>
        <OurCurriculumTeacher/>
    </div>
    <Footer/>
  </Layout>
);

export default OurCurriculumPage;
