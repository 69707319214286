import React from 'react';
import { Container, Section } from '../global';

const OurCurriculum = () => (
    <Section id="our-curriculum" >
    <Container>
      <h1>CURRICULUM</h1>
        <h2>Playing with a Purpose.</h2>
        <p>The purpose of this environment is to build skills. Play is crucial to the development of social interactions, intelligence, creativity, and the ability to problem-solve. </p>

    </Container>
  </Section>
);

export default OurCurriculum;
