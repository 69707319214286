import React from 'react';
import { Container, Section } from '../global';

import CircleDivs from '../../components/common/CircleDivs';
import chemistry from '../../../static/icons/001-chemistry.png';
import bookshelf from '../../../static/icons/002-bookshelf.png';
import brain from '../../../static/icons/003-brain.png';
import art from '../../../static/icons/004-fine-arts.png';
import calculator from '../../../static/icons/005-calculator.png';
import crayon from '../../../static/icons/006-crayon.png';
import apples from '../../../static/icons/006-healthy.png';


const content2 = [
  { brief: <img src={chemistry}  alt={'twitter'}/>,
    text: "Using objects to represent something different. Manipulate simple scientific tools, Ask, “why, what how”, Explore different solutions to problems",
    title: "Science"},
  { brief: <img src={bookshelf}  alt={'twitter'}/>,
    text: "Listen/respond to two-step instructions, Pretends to read, Retelling stories, Clearly identify familiar objects",
    title: "Literacy & Language"},
  { brief: <img src={apples}  alt={'twitter'} height="128" width="128"/>,
    text: "Bathroom routine/etiquette, Uses utensils with a purpose of self-feeding, Manipulates large objects",
    title: "Wellness"},
  { brief: <img src={art}  alt={'twitter'}/>,
    text: "Investigates materials through 5 senses, Utilize art materials, Begin imitation/role-playing, Respond to directional songs",
    title: "Arts"},
  { brief: <img src={calculator}  alt={'twitter'}/>,
    text: "Recognizes and names a few numerals, Recognition of shapes and colors, Ability to complete simple puzzles",
    title: "Math"},
  { brief: <img src={crayon}  alt={'twitter'}/>,
    text: "Introduction to writing tools, Opportunities to create scribbles, Uses finger and whole arm movement to explore objects",
    title: "Fine Motor"},
  { brief: <img src={brain}  alt={'twitter'}  />,
    text: "Accepts redirection from adults, Comforts self, Seek to do things with independence, Asserts needs by gesturing, pointing, or talking",
    title: "Social-Emotional"}
];

const OurCurriculumMobile = () => (
    <Section id="curriculum-twos">
        <Container>
            <h2>Twos</h2>
            <p><b><i>“Why fit in when you were born to stand out?”</i> - Dr. Suess</b></p>
            <CircleDivs items={content2}/>
        </Container>
    </Section>
);

export default OurCurriculumMobile;
