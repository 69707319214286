import React from 'react';
import { Container, Section } from '../global';

import CircleDivs from '../../components/common/CircleDivs';
import chemistry from '../../../static/icons/001-chemistry.png';
import bookshelf from '../../../static/icons/002-bookshelf.png';
import brain from '../../../static/icons/003-brain.png';
import art from '../../../static/icons/004-fine-arts.png';
import calculator from '../../../static/icons/005-calculator.png';
import crayon from '../../../static/icons/006-crayon.png';
import apples from '../../../static/icons/006-healthy.png';

const content2 = [
  { brief: <img src={chemistry}  alt={'twitter'}/>,
    text: "Investigate materials, Object permanence, Spatial awareness",
    title: "Science"},
  { brief: <img src={bookshelf}  alt={'twitter'}/>,
    text: "Listen/respond to one-step instructions, Vocabulary development, Interested in books",
    title: "Literacy & Language"},
  { brief: <img src={apples}  alt={'twitter'} height="128" width="128"/>,
    text: "Experiment with different ways to travel/move, Introduced to family-style eating with solid food, Brush their teeth after a meal",
    title: "Wellness"},
  { brief: <img src={art}  alt={'twitter'}/>,
    text: "Join in songs and games, Imitate actions in storytelling, Appreciation for music",
    title: "Arts"},
  { brief: <img src={calculator}  alt={'twitter'}/>,
    text: "Matching identical objects, Positional directions, Showing interest in simple patterns",
    title: "Math"},
  { brief: <img src={crayon}  alt={'twitter'}/>,
    text: "Different utensils during meals, Scribbling and markings, Variety of materials to improve muscle memory",
    title: "Fine Motor"},
  { brief: <img src={brain}  alt={'twitter'}  height="128" width="128"/>,
    text: "Calms self by seeking objects, Starts to indicate needs and wants, Build positive relationships with caregivers, Start to develop empathy",
    title: "Social-Emotional"}
];

const OurCurriculumMobile = () => (
    <Section id="curriculum-infant-mobile">
        <h2>Infant Mobile</h2>
        <p><b><i>“From there to here, from here to there, funny things are everywhere.”</i> - Dr. Suess</b></p>
        <CircleDivs items={content2}/>
    </Section>
);

export default OurCurriculumMobile;
