import React, { useEffect, useState } from 'react';
import { Mobile, MobileRow } from '../global';
import styled from 'styled-components';

const CircleDivs = props => {
  const n = props.items.length;
  const theta = 360 / n;

  const [items, setItems] = useState([]);
  const [index, setIndex] = useState(0);
  const [degrees, setDegrees] = useState(0);
  const [text, setText] = useState(props.items[0].text.split(',').map(item => {return <div>- {item}</div>}));
  const [title, setTitle] = useState(props.items[0].title);

  useEffect(() => {
    if (n > 0) {
      const radius = 200;
      const newItems = [];

      for (let i = 0; i < n; i++) {
        const posx = Math.round(radius * (Math.cos((theta / 180) * (i - (n / 4)) * Math.PI))) + 64;
        const posy = Math.round(radius * (Math.sin((theta / 180) * (i - (n / 4)) * Math.PI))) + 64;

        const style = {
          position: 'absolute',
          backgroundColor: props.items[i].color,
        };
        style.top = (radius - posx) + 'px';
        style.left = (radius - posy) + 'px';

        newItems.push({ ...props.items[i], style });
      }

      setItems(newItems);
    }
  }, [props.items]);

  if (items.length <= 0) {
    return null;
  }

  return (
    <Flex>
      <MobileRow hide>
        <OuterCircle style={{ backgroundColor: '#00A8B5' }}>
          <InnerCircle
            style={{ transform: `rotate(${degrees}deg)`, backgroundColor: '#00A8B5', transitionDuration: '800ms' }}>
            {items.map((item, i) => (

              <Item key={i} style={{ ...item.style, transform: `rotate(${degrees * -1}deg)` }}
                onClick={() => {
                  setIndex(i);

                  let diff = i - index;

                  if (Math.abs(diff) > n / 2) {
                    if (diff > 0) {
                      diff = diff - n
                    } else {
                      diff = diff + n
                    }
                  }
                  setDegrees(degrees + ((diff) * theta));
                  setText(item.text.split(',').map(item => {
                    return <div>- {item}</div>
                  }));
                  setTitle(item.title);
                }}>
                {item.brief}

              </Item>))}
          </InnerCircle>
        </OuterCircle>
        <CircleText style={{ backgroundColor: '#00A8B5'}}>
          <h2>{title}</h2>
          <div>{text}</div>
        </CircleText>
      </MobileRow>
      <Mobile>
        {items.map((item, i) => (
         <FlexMobile> <div key={i}>
            <dev>{item.brief}</dev>
            <br/>
            <b>{item.title}</b>
            <br/>
            -  {item.text}
          </div> 
          </FlexMobile>    
          ))}
      </Mobile>
    </Flex>
  );
};


const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 80px
`;

const FlexMobile = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 40px
`;

const CircleText = styled.div`
  margin-top: 200px;
  padding: 20px;

  text-align: center;
  margin: auto; /* Important */ 
  text-align: center; 

  min-hight: 250px;
  width: 350px;
  background: '#00A8B5';
  border-radius: 10px;
`;

const OuterCircle = styled.div`
  height: 600px;
  width: 600px;
  background: '#00A8B5';
  border-radius: 1000px;
`;

const InnerCircle = styled.div`
  height: 400px;
  width: 400px;
  border-radius: 1000px;
  margin: 100px;
`;

const Item = styled.div`
  position: absolute;
  width: 128px;
  height: 128px;
  text-align: center;
`;

export default CircleDivs;
