import React from 'react';
import { Container, Section } from '../global';

import CircleDivs from '../../components/common/CircleDivs';
import chemistry from '../../../static/icons/001-chemistry.png';
import bookshelf from '../../../static/icons/002-bookshelf.png';
import brain from '../../../static/icons/003-brain.png';
import art from '../../../static/icons/004-fine-arts.png';
import calculator from '../../../static/icons/005-calculator.png';
import apples from '../../../static/icons/006-healthy.png';
import crayon from '../../../static/icons/006-crayon.png';

const content2 = [
  { brief: <img src={chemistry}  alt={'twitter'}/>,
    text: "Observe, research, and chart findings, Conduct long-term projects",
    title: "Science"},
  { brief: <img src={bookshelf}  alt={'twitter'}/>,
    text: "Responds to complex statements, Increased written and spoken vocabulary, Reading for comprehension purposes",
    title: "Literacy & Language"},
  { brief: <img src={apples}  alt={'twitter'}  height="128" width="128"/>,
    text: "Independently participate in family-style meals, Independent self-care, Lead organized activities",
    title: "Wellness"},
  { brief: <img src={art}  alt={'twitter'}/>,
    text: "Creation of dance routines, Creation of plays or stories, Use of different tools and mediums",
    title: "Arts"},
  { brief: <img src={calculator}  alt={'twitter'}/>,
    text: "Solve numerical problems, Distinguish patterns,  Measure volume, and size of objects, Understanding the concept of time",
    title: "Math"},
  { brief: <img src={crayon}  alt={'twitter'}/>,
    text: "Uses small precise fingers and hand movement, Build structures using small legos, Cuts complex images",
    title: "Fine Motor"},
  { brief: <img src={brain}  alt={'twitter'}  height="128" width="128"/>,
    text: "Controls strong emotions, Apply rules to a new situation, Takes responsibility for own well being",
    title: "Social-Emotional"}
];

const OurCurriculumSchoolAge= () => (
    <Section id="curriculum-school-age">
      <h2>School Age</h2>
      <p><b><i>“It’s fun to have fun but you have to know how.”</i> - Dr. Suess</b></p>
        <CircleDivs items={content2}/>
    </Section>
);

export default OurCurriculumSchoolAge;
