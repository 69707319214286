import React from 'react';
import { Container, Section } from '../global';

import CircleDivs from '../../components/common/CircleDivs';
import chemistry from '../../../static/icons/001-chemistry.png';
import bookshelf from '../../../static/icons/002-bookshelf.png';
import brain from '../../../static/icons/003-brain.png';
import art from '../../../static/icons/004-fine-arts.png';
import calculator from '../../../static/icons/005-calculator.png';
import crayon from '../../../static/icons/006-crayon.png';
import apples from '../../../static/icons/006-healthy.png';

const content2 = [
  { brief: <img src={chemistry}  alt={'twitter'}/>,
    text: "Observe/Form experiments and try different solutions, Utilize magnifying glasses, maps, and additional tools properly, indoor and outdoor “laboratories”",
    title: "Science"},
  { brief: <img src={bookshelf}  alt={'twitter'}/>,
    text: " Responds to specific vocabulary and simple statements, Retell stories in proper sequence, Able to recognize rhyming words",
    title: "Literacy & Language"},
  { brief: <img src={apples}  alt={'twitter'} height="128" width="128" />,
    text: "Introduction to nutritional value in food, Independent bathroom routine, Starting to participate in organized group play",
    title: "Wellness"},
  { brief: <img src={art}  alt={'twitter'}/>,
    text: "Produce rhythmic movements, Creating art as a form of communication, Act out stories utilizing classroom materials",
    title: "Arts"},
  { brief: <img src={calculator}  alt={'twitter'}/>,
    text: "Identifies numerals in print, Ability to count in numerical order, Recognition of basic shapes and colors, Create sets of objects and sorting",
    title: "Math"},
  { brief: <img src={crayon}  alt={'twitter'}/>,
    text: "Uses refined wrist and finger movements, Working on buttons, zipping, lacing, and buckles, Holds drawing and writing tools by using a three-point finger grip",
    title: "Fine Motor"},
  { brief: <img src={brain}  alt={'twitter'} />,
    text: "Conflict resolution, Identify feeling/emotions, Develop friendships with other students, Manage classroom routines, Turn-taking",
    title: "Social-Emotional"}
];

const OurCurriculumPreschool = () => (
    <Section id="curriculum-preschool">
      <h2>Pre-School</h2>
      <p><b><i>“I like nonsense, it wakes up the brain cells. Fantasy is a necessary ingredient in living.”</i>- Dr. Seuss</b></p>
        <CircleDivs items={content2}/>
    </Section>
);

export default OurCurriculumPreschool;
