import React from 'react';
import { Container, Section } from '../global';

import CircleDivs from '../../components/common/CircleDivs';
import chemistry from '../../../static/icons/001-chemistry.png';
import bookshelf from '../../../static/icons/002-bookshelf.png';
import brain from '../../../static/icons/003-brain.png';
import art from '../../../static/icons/004-fine-arts.png';
import calculator from '../../../static/icons/005-calculator.png';
import crayon from '../../../static/icons/006-crayon.png';
import apples from '../../../static/icons/006-healthy.png';

const content2 = [
  { brief: <img src={chemistry}  alt={'twitter'}/>,
    text: "Explore natural elements Dump and pour materials, Cause and effect",
    title: "Science"},
  { brief: <img src={bookshelf}  alt={'twitter'}/>,
    text: "Tuning into voices, Exploring pictures, Listen and Respond ",
    title: "Literacy & Language"},
  { brief: <img src={apples}  alt={'twitter'}  height="128" width="128"/>,
    text: "Explore different foods, Move to explore the immediate environment, Handwashing routine",
    title: "Wellness"},
  { brief: <img src={art}  alt={'twitter'}/>,
    text: "Exposure to different shapes, colors, textures, Listen to different sounds and rhythms",
    title: "Arts"},
  { brief: <img src={calculator}  alt={'twitter'}/>,
    text: "A conscious effort to include basic math concepts during interactions, Wide variety of materials in different shapes",
    title: "Math"},
  { brief: <img src={crayon}  alt={'twitter'}/>,
    text: "Variety of objects to grab, Finger-thumb pinch",
    title: "Fine Motor"},
  { brief: <img src={brain}  alt={'twitter'}/>,
    text: "Develop strong bonds, Respond to others, Build trust, Adult support to calm self",
    title: "Social-Emotional"}
];

const OurCurriculumInfants = () => (
    <Section id="curriculum-infant">
        <h2>Infants</h2>
        <p><b><i>“A person’s a person no matter how small”</i> - Dr. Suess</b></p>
        <CircleDivs items={content2}/>
    </Section>
);

export default OurCurriculumInfants;
